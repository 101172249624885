import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'talixio-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit  {
  title = 'Accueil';
      
  constructor() {
  }
  
  ngOnInit() {
  }
}
