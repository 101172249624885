import { Pipe, PipeTransform } from '@angular/core';

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_MONTH = 30 * ONE_DAY;
const ONE_YEAR = 365 * ONE_DAY;

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
 transform(value: any): string {
 	if (value) {
 		const now = new Date();
 		const diff = (value.getTime() - now.getTime()) / 1000;
 		
 		if (diff < 0) {
 			return 'il y a ' + this.getDiff(-diff);
 		} else {
 			return 'dans ' + this.getDiff(diff);
 		}
 	}
  }
  
  getDiff(diff: number) {
  	if (diff > ONE_YEAR) {
  		console.log('debug:', diff, diff / ONE_YEAR,  Math.floor(diff / ONE_YEAR));
		return Math.floor((diff / ONE_YEAR)) + ' années';
	} else if (diff > ONE_MONTH) {
		return Math.floor(diff / (ONE_MONTH)) + ' mois';
	} else if (diff > ONE_DAY) {
		return Math.floor(diff / ONE_DAY) + ' j';
	} else if (diff > ONE_HOUR) {
		return Math.floor(diff / ONE_HOUR) + ' h';
	} else if (diff > ONE_MINUTE) {
		return Math.floor(diff / ONE_MINUTE) + ' mn';
	} else if (diff > 0) {
		return '1 mn';
	} else if (diff == 0) {
		return 'maintenant';
	}
	return 'error';
  }
}