import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'arrayJoin'})
export class ArrayJoinPipe implements PipeTransform {
 transform(value: Array<number>): string {
 	const result = [];
 	if (!value || value.length == 0) {
 		return "Aucun";
 	} else {
	 	for (const v of value) {
	 		if (Array.isArray(v)) {
	 			result.push(v.join('/'));
 			} else {
 				result.push(v);
 			}
	 	}
	    return result.join(', ');
	}
  }
}