import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SupervisorService } from '../../services/supervisor.service';


@Component({
  selector: 'supervisors',
  templateUrl: './supervisors.component.html',
  styleUrls: ['./supervisors.component.css']
})
export class SupervisorsComponent implements OnInit  {
  
  @Input() filter = 'ALL';
  
  supervisors: Array<any> = [];
  
  constructor(
  	private readonly router: Router,
  	private readonly route: ActivatedRoute,
  	private readonly supervisorService: SupervisorService) {
  }
  
  ngOnInit() {
  	this.route.paramMap.subscribe((params: ParamMap) => {
        this.supervisorService.getAll(this.filter).subscribe(r => {
        	const tmp: Array<any> = [];
        	for (const s of r) {
        		for (const t of s['tasks']) {
        			const status = {ip: s.ip, collectedAt: t.collectedAt, collectedAtStatus: t.collectedAtStatus, name: t.name, status: t.status, statusStatus: t.statusStatus };
        			tmp.push(status);
        		}
        	}
        	this.supervisors = tmp;
        });
      }
    );
  }
  
   onSort($event) {
  }
  
  onSelect({ selected }) {
  }
  
  
  rowClass(row: any) {
  	return row.blackedListed ? 'bg-light' : '';
  }
  
  statusStatusClass({ row, column, value }): any {
    return {
        'bg-success': row.statusStatus === 'OK',
        'bg-warning': (row.statusStatus === 'WARNING'),
        'bg-danger': row.statusStatus === 'DANGER',
    };
  }
  
   collectedAtClass({ row, column, value }): any {
    return {
        'bg-success': row.collectedAtStatus === 'OK',
        'bg-warning': (row.collectedAtStatus === 'WARNING'),
        'bg-danger': row.collectedAtStatus === 'DANGER',
    };
  }
  
  updateFilter($event) {
  }
  
}
