import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { OvhNickhandleService } from '../../services/ovh-nickhandle.service';


@Component({
  selector: 'nickhandle',
  templateUrl: './nickhandle.component.html',
  styleUrls: ['./nickhandle.component.css']
})
export class NickhandleComponent implements OnInit  {
  modalRef: BsModalRef;
  
  nickhandle : any;
  selected : string;
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute,
  	private readonly ovhNickhandle: OvhNickhandleService) {
  }
  
  ngOnInit() {
  	this.route.paramMap.subscribe((params: ParamMap) => {
        this.nickhandle = this.ovhNickhandle.getNickhandle(params.get('nickhandle'));
      }
    );
  }
  
  activeClass(selected: string) {
  	return this.selected && this.selected === selected ? 'btn btn-outline-success mr-2 my-sm-0 text-success' : 'btn btn-outline-secondary mr-2 my-sm-0 text-secondary';
  }
  
  show($event, selected) {
  	this.selected = selected;
  	this.router.navigate(['./' + selected], { relativeTo: this.route });
  	$event.preventDefault();
  }
}
