import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'instance-disk',
  templateUrl: './instance-disk.component.html',
  styleUrls: ['./instance-disk.component.scss']
})
export class InstanceDiskComponent implements OnInit  {
  modalRef: BsModalRef;
  
  @Input() nickhandle : any;
  @Input() disk : any;
  projectId : string;
  instanceId : string;
  
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute) {
  }
  
  ngOnInit() {
  	
  }
 
  updatedAtStatusClass(value): any {
    switch (value) {
    	case 'OK': return 'bg-success';
    	case 'WARNING': return 'bg-warning';
    	case 'DANGER': return 'bg-danger';
    };
  }
  
  disks() {
  	const result = [];
  	if (this.disk && this.disk.disks) {
  		for (let k in this.disk.disks) {
  			result.push(this.disk.disks[k]);
  		}
  	}
  	return result;
  }
}
