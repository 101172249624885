import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { OvhNickhandleService } from './services/ovh-nickhandle.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ 'app.component.scss' ]
})
export class AppComponent implements OnInit  {
  title = 'ACR - Console d\'administration';
  error : string;
  version = '0.1.2 (2020.05.27)';
  nickhandles = [];
  selected : any;
  
  constructor(
  	private readonly authService: AuthService,
  	private readonly ovhNickhandle: OvhNickhandleService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute) {
  }
  
  ngOnInit() {
  	this.ovhNickhandle.getNickhandles().subscribe(
  		result => this.nickhandles = result
  	);
  }
  
  logout($event) {
  	this.authService.logout();
  	$event.preventDefault();
  }
  
  selectNickhandle($event, nickhandle: any) {
  	this.selected = nickhandle;
  	this.router.navigate(['./nickhandle', nickhandle.code, 'alerts'], { relativeTo: this.route });
  	$event.preventDefault();
  }
  
}
