import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, map } from 'rxjs/operators';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  constructor(
    private readonly http: HttpClient,
  	private readonly router: Router) 
  { }
  
  handleError(error) {
  	console.log("handleError", error);
  	if (error.url.indexOf('/login') !== -1) {
  		console.log("redirecting to login page");
  		window.location.replace('/login');
  	}
  }
  
  getAll(filter : string) : any {
	return this.http.get('/api/supervisor?filter=' + filter );
  }
}
