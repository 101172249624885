import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { OvhNickhandleService } from '../../services/ovh-nickhandle.service';


@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit  {
  modalRef: BsModalRef;
  
  nickhandle : any;
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute,
  	private readonly ovhNickhandle: OvhNickhandleService) {
  }
  
  ngOnInit() {
  	this.route.paramMap.subscribe((params: ParamMap) => {
        console.log(params.get('nickhandle'));
        this.nickhandle = this.ovhNickhandle.getNickhandle(params.get('nickhandle'));
      }
    );
  }
  
}
