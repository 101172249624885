import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { OvhNickhandleService } from '../../services/ovh-nickhandle.service';
import { OvhSnapshotService } from '../../services/ovh-snapshot.service';
import { InstanceSnapshotsComponent } from '../instance-snapshots/instance-snapshots.component';

@Component({
  selector: 'snapshots',
  templateUrl: './snapshots.component.html',
  styleUrls: ['./snapshots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnapshotsComponent implements OnInit  {
  modalRef: BsModalRef;
  
  nickhandle : any;
  snapshots : any;
  selected : any;
  instanceSnapshots : Array<any>;
  rows : any;
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  @Input() filter = 'ALL';
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute,
  	private readonly snapshotService: OvhSnapshotService,
  	private readonly nickhandleService: OvhNickhandleService) {
  }
  
  ngOnInit() {
  	this.route.parent.paramMap.subscribe((params: ParamMap) => {
        const nickhandle = params.get('nickhandle');
        this.nickhandleService.getNickhandle(nickhandle).subscribe(
        	data => 
        		{ 
        			this.nickhandle = data;  
        		}
        	);
        this.snapshotService.getBackupStatus(nickhandle, this.filter).subscribe(
        	data =>
        		{
        			this.rows = this.snapshots = data;
        			this.loadingIndicator = false;
        		}
        );
      }
    );
  }
  
  
  onSort($event) {
  }
  
  onSelect({ selected }) {
  	this.selected = selected[0];
  	this.instanceSnapshots = null;
  	this.snapshotService.getInstanceSnapshots(this.nickhandle.code, selected[0].projectId, selected[0].instanceId)
  		.subscribe(result => {
  			console.log(result);
  			this.instanceSnapshots = result;
  		});
  }
  
  
  rowClass(row: any) {
  	return row.blackedListed ? 'bg-light' : '';
  }
  
  statusClass({ row, column, value }): any {
    return {
        'bg-success': !row.blackedListed && row.status === 'OK',
        'bg-warning': !row.blackedListed &&  (row.status === 'MISSING' || row.status === 'TOO_MANY'),
        'bg-danger': !row.blackedListed &&  row.status === 'NONE',
    };
  }
  
  updateFilter($event) {
  	if (!event.target['value']) {
  		return;
  	}
  	const val = event.target['value'].toLowerCase();

    // filter our data
    const temp = this.snapshots.filter(function(d) {
      let result = false;
      for (const a in d) {
      	result = result || String(d[a]).toLowerCase().indexOf(val) !== -1 || !val;
      	if (result) break;
      }
      return result;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
//    this.table.offset = 0;
  }
}
