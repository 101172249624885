import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class OvhSnapshotService {
  nickhandles: any;

  constructor(
    private readonly http: HttpClient,
  	private readonly router: Router) 
  { }
  
  handleError(error) {
  	console.log("handleError", error);
  	if (error.url.indexOf('/login') !== -1) {
  		console.log("redirecting to login page");
  		window.location.replace('/login');
  	}
  }
  
  getAllSummaries(nickhandleCode : string) : any {
	return this.http.get('/api/ovh/nickhandle/' + nickhandleCode + '/snapshots');
  }
  
  getInstanceSnapshots(nickhandleCode : string, projectId : string, instanceId : string) : any {
	return this.http.get('/api/ovh/nickhandle/' + nickhandleCode + '/' + projectId + '/' + instanceId + '/snapshots');
  }
  
  getBackupStatus(nickhandleCode : string, filter : string) : any {
	return this.http.get('/api/ovh/nickhandle/' + nickhandleCode + '/backup-status?filter=' + filter );
  }

}
