import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toDate'})
export class LocalDateTimePipe implements PipeTransform {
 transform(value: any): Date {
 	let result : Date;
 	if (value) {
 		if (typeof value === 'string') {
 			const split = value.split('T');
 			let dateStr = split[0];
 			const timeStr = split[1];
 			const time = timeStr.split(':');
 			
 			let date : any;
 			if (dateStr[0] == '-') {
 				date = dateStr.split('-');
 				date[1] = "0";
 				date.shift();
 			} else if (dateStr[0] == '+') {
 				dateStr = dateStr.substr(1);
 				date = dateStr.split('-');
 				date[0] = "2200";
 				//console.log(value, Number(date[0]), Number(date[1]) -1, Number(date[2]),Number(time[0]),Number(time[1]));
 			} else {
 				date = dateStr.split('-');
 			}
 			
 			
 			result =  new Date(Number(date[0]),Number(date[1]) - 1 ,Number(date[2]),Number(time[0]),Number(time[1]));
 			
 			//console.log("value:", value, " => result:", result);
 		} else {
	 		if (value[0] < 0) value[0] = 0;
	 		result = new Date(value[0],value[1],value[2],value[3],value[4]);
 		} 
 	}
 	
 	return result;
  }
}