import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'instance-snapshots',
  templateUrl: './instance-snapshots.component.html',
  styleUrls: ['./instance-snapshots.component.scss']
})
export class InstanceSnapshotsComponent implements OnInit  {
  modalRef: BsModalRef;
  
  @Input() nickhandle : any;
  @Input() snapshots : Array<any>;
  projectId : string;
  instanceId : string;
  
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute) {
  }
  
  ngOnInit() {
  	
  }
}
