import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'instance-dns',
  templateUrl: './instance-dns.component.html',
  styleUrls: ['./instance-dns.component.scss']
})
export class InstanceDnsComponent implements OnInit  {
  modalRef: BsModalRef;
  
  @Input() nickhandle : any;
  @Input() dns : any;
  projectId : string;
  instanceId : string;
  
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute) {
  }
  
  ngOnInit() {
  	
  }
 
  updatedAtStatusClass(value): any {
    switch (value) {
    	case 'OK': return 'bg-success';
    	case 'WARNING': return 'bg-warning';
    	case 'DANGER': return 'bg-danger';
    };
  }
  
  getCertificateExpirationDateClass(value) : string {
  	let result = 'bg-success';
  	const today = new Date();
  	const date = new Date(value);
  	const nbDays = (date.getTime() - today.getTime()) / (1000 * 3600 * 24);
  	
  	if (nbDays <= 15) {
  		result = 'bg-danger';
  	} else if (nbDays <= 30) {
  		return 'bg-warning';
  	}
  	return result; 
  }
  
  getDns() {
  	const result = [];
  	if (this.dns && this.dns.dns) {
  		for (let k in this.dns.dns) {
  			result.push(this.dns.dns[k]);
  		}
  	}
  	return result;
  }
}
