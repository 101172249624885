import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { OvhNickhandleService } from '../../services/ovh-nickhandle.service';
import { OvhMonitService } from '../../services/ovh-monit.service';


@Component({
  selector: 'systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.css']
})
export class SystemsComponent implements OnInit  {
  modalRef: BsModalRef;
  
  nickhandle : any;
  systems : any;
  selected : any;
  instanceSystem : any;
  rows : any;
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  @Input() filter = 'ALL';
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute,
  	private readonly nickhandleService: OvhNickhandleService,
  	private readonly monitService: OvhMonitService) {
  }
  
  ngOnInit() {
  	this.route.parent.paramMap.subscribe((params: ParamMap) => {
  		console.log(params	);
        const nickhandle = params.get('nickhandle');
        this.nickhandleService.getNickhandle(nickhandle).subscribe(
        	data => 
        		{ 
        			this.nickhandle = data;  
        		}
        	);
        this.monitService.getSystemStatus(nickhandle, this.filter).subscribe(
        	data =>
        		{
        			this.rows = this.systems = data;
        			this.loadingIndicator = false;
        		}
        );
      }
    );
   
  }
  
  memoryStatusClass({ row, column, value }): any {
    return {
        'bg-success': row.memoryStatus === 'OK',
        'bg-warning': row.memoryStatus === 'WARNING',
        'bg-danger': row.memoryStatus === 'NONE',
    };
  }
  
  loadStatusClass({ row, column, value }): any {
    return {
        'bg-success': row.loadStatus === 'OK',
        'bg-warning': row.loadStatus === 'WARNING',
        'bg-danger': row.loadStatus === 'DANGER',
    };
  }
  
  updatedAtStatusClass({ row, column, value }): any {
    return {
        'bg-success': row.updatedAtStatus === 'OK',
        'bg-warning': row.updatedAtStatus === 'WARNING',
        'bg-danger': row.updatedAtStatus === 'DANGER',
    };
  }
  
   onSort($event) {
  }
  
  onSelect({ selected }) {
  	this.selected = selected[0];
  	this.instanceSystem = null;
  	this.monitService.getInstanceSystem(this.nickhandle.code, selected[0].projectId, selected[0].instanceId)
  		.subscribe(result => {
  			console.log(result);
  			this.instanceSystem = result;
  		});
  }
  
  
}
