import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule, Routes } from '@angular/router'; 
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap';
import { TabsModule, ModalModule } from 'ngx-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { FileSizeModule } from 'ngx-filesize';
import { NgMathPipesModule } from 'angular-pipes';

import { AppComponent } from './app.component';

import { HomeComponent } from './components/home/home.component';

import { NickhandleComponent } from './components/nickhandle/nickhandle.component';

import { AlertsComponent } from './components/alerts/alerts.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { SnapshotsComponent } from './components/snapshots/snapshots.component';
import { SystemsComponent } from './components/systems/systems.component';
import { DisksComponent } from './components/disks/disks.component';
import { DnsComponent } from './components/dns/dns.component';
import { PingsComponent } from './components/pings/pings.component';
import { SupervisorsComponent } from './components/supervisors/supervisors.component';
import { BackupsComponent } from './components/backups/backups.component';

import { InstanceSnapshotsComponent } from './components/instance-snapshots/instance-snapshots.component';
import { InstanceSystemComponent } from './components/instance-system/instance-system.component';
import { InstanceDiskComponent } from './components/instance-disk/instance-disk.component';
import { InstanceDnsComponent } from './components/instance-dns/instance-dns.component';

import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { LocalDateTimePipe } from './pipes/localdatetime.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';

import { AuthService } from './services/auth.service';
import { OvhNickhandleService } from './services/ovh-nickhandle.service';
import { OvhSnapshotService } from './services/ovh-snapshot.service';
import { OvhMonitService } from './services/ovh-monit.service';
import { SupervisorService } from './services/supervisor.service';

const appRoutes: Routes = [
	{ path: 'home', component: HomeComponent },
	{ path: 'nickhandle/:nickhandle', 
	  component: NickhandleComponent,
		children: [
			{
	            path: 'alerts',
	            component: AlertsComponent
	        },
	        {
	            path: 'configurations',
	            component: ConfigurationsComponent
	        },
	        {
	            path: 'snapshots',
	            component: SnapshotsComponent
	        },
	        {
	            path: 'systems',
	            component: SystemsComponent
	        },
	        {
	            path: 'disks',
	            component: DisksComponent
	        },
	        {
	            path: 'dns',
	            component: DnsComponent
	        },
	        {
	            path: 'pings',
	            component: PingsComponent
	        },
	        {
	            path: 'backups',
	            component: BackupsComponent
	        },
	        {
	            path: ':projectId/:instanceId/snapshots',
	            component: InstanceSnapshotsComponent
	        },
	        {
	            path: ':projectId/:instanceId/system',
	            component: InstanceSystemComponent
	        },
	        {
	            path: ':projectId/:instanceId/disks',
	            component: InstanceDiskComponent
	        },
	        {
	            path: ':projectId/:instanceId/dns',
	            component: InstanceDnsComponent
	        }
	    ]
	},
	{ path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
  	NgxDatatableModule,
    BrowserModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    FileSizeModule,
    NgMathPipesModule,
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
 	RouterModule.forRoot(appRoutes)
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    NickhandleComponent,
    
    AlertsComponent,
    ConfigurationsComponent,
    SnapshotsComponent,
    SystemsComponent,
    DisksComponent,
    DnsComponent,
    PingsComponent,
    SupervisorsComponent,
    BackupsComponent,
    
    InstanceSnapshotsComponent,
    InstanceSystemComponent,
    InstanceDiskComponent,
    InstanceDnsComponent,
    
    TimeAgoPipe,
    ArrayJoinPipe,
    LocalDateTimePipe
  ],
  providers: [
  			  AuthService, 
  			  OvhNickhandleService,
  			  OvhSnapshotService,
  			  OvhMonitService,
  			  SupervisorService
  			  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
