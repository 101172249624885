import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'instance-system',
  templateUrl: './instance-system.component.html',
  styleUrls: ['./instance-system.component.scss']
})
export class InstanceSystemComponent implements OnInit  {
  modalRef: BsModalRef;
  
  @Input() nickhandle : any;
  @Input() system : Array<any>;
  projectId : string;
  instanceId : string;
  
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  
  constructor(
  	private readonly modalService: BsModalService,
  	private readonly router: Router,
  	private readonly route: ActivatedRoute) {
  }
  
  ngOnInit() {
  	
  }
  
   memoryStatusClass(value): any {
     switch (value) {
    	case 'OK': return 'bg-success';
    	case 'WARNING': return 'bg-warning';
    	case 'DANGER': return 'bg-danger';
    };
  }
  
  loadStatusClass(value): any {
     switch (value) {
    	case 'OK': return 'bg-success';
    	case 'WARNING': return 'bg-warning';
    	case 'DANGER': return 'bg-danger';
    };
  }
  
  updatedAtStatusClass(value): any {
    switch (value) {
    	case 'OK': return 'bg-success';
    	case 'WARNING': return 'bg-warning';
    	case 'DANGER': return 'bg-danger';
    };
  }
}
